
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { computed, ComputedRef, defineComponent } from "vue";
import IconCheck from "../../icons/IconCheck.vue";
import { CheckboxStyleInterface } from "../input_interfaces";
import MetaHandler from "../metaHandler/MetaHandler.vue";
import { metaHandlerDefaultStyle } from "../metaHandler/meta_handler_default_style";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import {
    inputCheckboxDefaultStyle,
    inputCheckboxFilledDefaultStyle,
} from "./input_checkbox_default_style";

export default defineComponent({
    components: { IconCheck, MetaHandler },
    name: "InputCheckbox",
    props: {
        /**
         * unique id for the component
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * unique name for the component
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * value of the component
         */
        modelValue: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Text that is shown next to the checkbox
         */
        label: {
            type: String,
            required: false,
        },
        /**
         * toogles whether the checkbox is filled or not
         */
        isFilled: {
            type: Boolean,
            default: false,
        },
        /**
         * option to prevent user to edit input
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * toogles whether the input is required or not
         */
        isRequired: {
            type: Boolean,
            default: false,
        },
        /**
         * if this is set the input saves space for the bottom message and the message
         * can get displayed
         */
        hasMeta: {
            type: Boolean,
            default: true,
        },
        /**
         * Configuration Object for Meta Messages with
         * following attributes:
         * {
         *      ! errorMessage?: string;
         *      ! infoMessage?: string;
         *      ! saveMessage?: string;
         * }
         */
        inputMeta: {
            type: Object as () => InputMetaInterface,
            default: {} as InputMetaInterface,
        },

        /**
         * Configuration Object for Input Style with
         * following attributes:
         * {
         *      ! borderColor?: string;
         *      ! hoverBorderColor?: string;
         *      ! focusBorderColor?: string;
         *      ! activeColor?: string;
         *      ! activeFocusColor?: string;
         *      ! activeHoverColor?: string;
         *      ! checkColor?: string;
         *      ! labelClasses?: string;
         *
         *      ! errorColor?: string;
         *      ! saveColor?: string;
         *      ! infoColor?: string;
         * }
         */
        checkboxStyle: {
            type: Object as () => CheckboxStyleInterface,
            default: {} as CheckboxStyleInterface,
        },
    },
    emits: ["update:modelValue", "change"],
    setup(props, ctx) {
        const finalInputCheckboxStyle: ComputedRef<CheckboxStyleInterface> =
            computed(() => {
                const defaultStyle: CheckboxStyleInterface = props.isFilled
                    ? inputCheckboxFilledDefaultStyle
                    : inputCheckboxDefaultStyle;
                return {
                    ...defaultStyle,
                    ...metaHandlerDefaultStyle,
                    ...getSpaceConfigStyle()?.metaHandler,
                    ...getSpaceConfigStyle()?.inputCheckbox,
                    ...props.checkboxStyle,
                };
            });

        function handleClickEvent() {
            if (props.isDisabled != true) {
                ctx.emit("update:modelValue", !props.modelValue);
                ctx.emit("change", !props.modelValue);
            }
        }

        return {
            handleClickEvent,
            finalInputCheckboxStyle,
        };
    },
});
