
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import AuthContainer from "./containers/AuthContainer.vue";
import InputCheckbox from "../../components/inputs/inputCheckbox/InputCheckbox.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "../../store/auth";
import { InputMetaInterface } from "@/components/inputs/metaHandler/meta_handler_interfaces";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { MetaInformationInterface } from "@/store/helpers/interfaces";
import { getCookie } from "@/common/cookies";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
    name: "Login",
    components: {
        AuthContainer,
        CustomButton,
        InputCheckbox,
        InputDefault,
    },
    setup() {
        const metaInfo = ref<InputMetaInterface>({
            errorMessage: "",
            infoMessage: "",
            saveMessage: "",
        });
        const hasMeta = ref<boolean>(false);
        const { errorMessages } = storeToRefs(useHelpersStore());
        const { authUser } = storeToRefs(useAuthStore());
        const { login } = useAuthStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const router = useRouter();

        async function userLogin(): Promise<void> {
            setLoadingId("login-button");
            await login();
            removeLoadingId("login-button");
        }

        const route = useRoute();
        const buttonStyle = computed(() => {
            let style = {};
            if (route.name?.toString().includes("CustomerAuth")) {
                style = {
                    standardBackgroundColor: "black-lightest",
                    standardBackgroundHoverColor: "black-light",
                    standardBackgroundPressedColor: "black-light",
                    standardBorderPressedColor: "black-light",
                    standardBorderFocusColor: "black-light",
                };
            }
            return style;
        });

        const forgotPasswordRoute = computed(() => {
            let routeName = "AuthForgotPassword";
            if (route.name?.toString().includes("CustomerAuth")) {
                routeName = "CustomerAuthForgotPassword";
            }
            return routeName;
        });

        const emailError = computed(() => {
            let errorMessage = null as string | null;
            errorMessages.value.forEach((error: MetaInformationInterface) => {
                if (error.name == "email" && error.value) {
                    errorMessage = error.value;
                }
            });
            return errorMessage;
        });

        const passwordError = computed(() => {
            let errorMessage = null as string | null;
            errorMessages.value.forEach((error: MetaInformationInterface) => {
                if (error.name == "password" && error.value) {
                    errorMessage = error.value;
                }
            });
            return errorMessage;
        });

        onMounted(() => {
            if (getCookie("logged_in")) {
                router.push({ name: "NormAdministration" });
            }
        });

        return {
            metaInfo,
            hasMeta,
            login,
            authUser,
            errorMessages,
            emailError,
            passwordError,
            userLogin,
            buttonStyle,
            forgotPasswordRoute,
        };
    },
});
