import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full h-full" }
const _hoisted_2 = { class: "w-full flex flex-col justify-start items-start gap-y-2 overflow-auto scrollbar pr-1" }
const _hoisted_3 = { class: "w-full mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_auth_container = _resolveComponent("auth-container")!

  return (_openBlock(), _createBlock(_component_auth_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_input_default, {
            id: 'email',
            name: 'email',
            class: "w-full",
            label: _ctx.$t('auth.email'),
            inputMeta: { errorMessage: _ctx.emailError },
            onEnter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.userLogin())),
            modelValue: _ctx.authUser.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.authUser.email) = $event))
          }, null, 8, ["label", "inputMeta", "modelValue"]),
          _createVNode(_component_input_default, {
            id: 'password',
            name: 'password',
            type: 'password',
            class: "w-full",
            inputMeta: { errorMessage: _ctx.passwordError },
            hasPasswordVisibilityToggle: true,
            label: _ctx.$t('auth.password'),
            onEnter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.userLogin())),
            modelValue: _ctx.authUser.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.authUser.password) = $event))
          }, null, 8, ["inputMeta", "label", "modelValue"])
        ]),
        _createVNode(_component_custom_button, {
          id: 'login-button',
          class: "w-full mt-5",
          buttonStyle: _ctx.buttonStyle,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.userLogin()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("auth.login")), 1)
          ]),
          _: 1
        }, 8, ["buttonStyle"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_input_checkbox, {
            modelValue: _ctx.authUser.trust,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.authUser.trust) = $event)),
            id: "checkbox",
            name: "checkbox",
            label: _ctx.$t('auth.remember')
          }, null, 8, ["modelValue", "label"])
        ]),
        _createVNode(_component_custom_button, {
          id: 'forgot-password-button',
          class: "w-full mt-6",
          isPlainButton: true,
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({ name: _ctx.forgotPasswordRoute })))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("auth.forgot_password")) + "?", 1)
          ]),
          _: 1
        }),
        (!_ctx.$route.name?.toString().includes('CustomerAuth'))
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 0,
              id: 'forgot-password-button',
              class: "w-full mt-auto",
              isPlainButton: true,
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push({ name: 'CustomerAuthLogin' })))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("auth.to_customer_login")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}