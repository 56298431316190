import { CheckboxStyleInterface } from "../input_interfaces";

export const inputCheckboxDefaultStyle: CheckboxStyleInterface = {
    borderColor: "grey",
    hoverBorderColor: "grey-dark",
    focusBorderColor: "grey-dark",
    activeColor: "grey",
    activeHoverColor: "grey-dark",
    activeFocusColor: "grey-dark",
    checkColor: "primary",
    labelClasses: "text-md text-black",
};
export const inputCheckboxFilledDefaultStyle: CheckboxStyleInterface = {
    borderColor: "grey-lighter",
    hoverBorderColor: "grey-light",
    focusBorderColor: "grey",
    activeColor: "primary",
    activeHoverColor: "primary-dark",
    activeFocusColor: "primary-dark",
    checkColor: "white",
};
