import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "check",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1749",
      "data-name": "Rechteck 1749",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "check-2",
      "data-name": "check",
      d: "M228.46,106.48a1,1,0,0,1-.755-.345l-4.334-5a1,1,0,1,1,1.512-1.31l3.577,4.128,7.911-9.128a1,1,0,1,1,1.512,1.31l-8.667,10A1,1,0,0,1,228.46,106.48Z",
      transform: "translate(-218.127 -88.48)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}