import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = ["tabindex"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_meta_handler = _resolveComponent("meta-handler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: _ctx.id,
      name: _ctx.name,
      class: _normalizeClass(["flex flex-row items-center group w-full", _ctx.isDisabled ? '' : 'cursor-pointer']),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (
                _ctx.isDisabled
                    ? ''
                    : [
                          _ctx.$emit('update:modelValue', !_ctx.modelValue),
                          _ctx.$emit('change', !_ctx.modelValue),
                      ]
            ))
    }, [
      _createElementVNode("div", {
        tabindex: _ctx.isDisabled ? -1 : 0,
        class: _normalizeClass(["flex items-center justify-center border-2 rounded-md w-6 h-6 transition-all outline-none", [
                    _ctx.label ? 'mr-2' : '',
                    `bg-${
                        _ctx.modelValue
                            ? _ctx.isFilled
                                ? _ctx.finalInputCheckboxStyle.activeColor
                                : 'transparent'
                            : 'transparent'
                    } border-${
                        _ctx.modelValue
                            ? _ctx.finalInputCheckboxStyle.activeColor
                            : _ctx.finalInputCheckboxStyle.borderColor
                    } group-hover:border-${
                        _ctx.modelValue
                            ? _ctx.finalInputCheckboxStyle.activeHoverColor
                            : _ctx.finalInputCheckboxStyle.hoverBorderColor
                    } group-hover:bg-${
                        _ctx.modelValue
                            ? _ctx.isFilled
                                ? _ctx.finalInputCheckboxStyle.activeHoverColor
                                : 'transparent'
                            : 'transparent'
                    } focus:border-${
                        _ctx.modelValue
                            ? _ctx.finalInputCheckboxStyle.activeFocusColor
                            : _ctx.finalInputCheckboxStyle.focusBorderColor
                    } focus-hover:bg-${
                        _ctx.modelValue
                            ? _ctx.isFilled
                                ? _ctx.finalInputCheckboxStyle.activeFocusColor
                                : 'transparent'
                            : 'transparent'
                    }`,
                ]]),
        onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (
                    _ctx.isDisabled
                        ? ''
                        : [
                              _ctx.$emit('update:modelValue', !_ctx.modelValue),
                              _ctx.$emit('change', !_ctx.modelValue),
                          ]
                ), ["enter"]))
      }, [
        _createVNode(_component_icon_check, {
          class: _normalizeClass(["transition-all", _ctx.modelValue ? 'opacity-100' : 'w-0 h-0 opacity-0']),
          color: _ctx.finalInputCheckboxStyle.checkColor
        }, null, 8, ["class", "color"])
      ], 42, _hoisted_3),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id,
            class: _normalizeClass([
                    _ctx.finalInputCheckboxStyle.labelClasses,
                    _ctx.isDisabled ? '' : 'cursor-pointer',
                ])
          }, _toDisplayString(_ctx.label + (_ctx.isRequired ? " *" : "")), 11, _hoisted_4))
        : _createCommentVNode("", true)
    ], 10, _hoisted_2),
    (_ctx.hasMeta)
      ? (_openBlock(), _createBlock(_component_meta_handler, {
          key: 0,
          metaStyle: _ctx.checkboxStyle,
          inputMeta: _ctx.inputMeta
        }, null, 8, ["metaStyle", "inputMeta"]))
      : _createCommentVNode("", true)
  ]))
}